.recommends {

	&__container {
		&.is-sertificates,
		&.is-license {
			margin-bottom: 220px;
			@include r(1023) {
				margin-bottom: 140px; }
			@include r(767) {
				margin-bottom: 100px; }

			.recommends {
				&__img {
					padding: 20px;
					background-color: $white;
					img {
						height: 450px;
						@include r(639) {
							height: 300px; } } }
				&__text {
					background-color: transparent;
					padding: 30px 30px 0; }
				&__arrows {
					justify-content: flex-end;
					path,
					rect {
						fill: $violet; } }
				&__slide {
					cursor: pointer; } } }

		&.is-license {
			.slick-track {
				display: flex;
				align-items: stretch; }

			.slick-slide {
				height: auto;
				> div {
					height: 100%; } }

			.recommends {
				&__slide {
					height: 100%;
					display: flex!important;
					flex-direction: column;
 }					// background-color: $white

				&__text {
					padding: 30px;
					background-color: $white;
					flex-grow: 1;
					border-top: 1px solid rgba($grey, .3); } } } }

	&__slider {
		margin: 0 -10px 60px -10px;
		// .slick-slide
 }		// 	margin: 0 10px 0 7px

	&__slide {
		display: inline-block;
		max-width: 100%;
		width: 100%;
		padding: 10px; }

	&__img {
		position: relative;
		img {
			height: 360px;
			width: 100%;
			object-fit: cover;

			@include r(639) {
				height: 300px; }
			@include r(479) {
				height: 260px; } } }

	&__video-button {
		position: absolute;
		width: 100px;
		height: 100px;
		bottom: 30px;
		left: 30px;
		display: block;
		background-color: transparent;
		border: 0;
		cursor: pointer;
		z-index: 1;
		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: rotate(45deg) translate(-50%, -50%);
			transform-origin: left top;
			transition: transform .33s; }

		&::before {
			width: 60%;
			height: 60%;
			background-color: rgba($white, .5); }


		&::after {
			width: 70%;
			height: 70%;
			border: 1px solid $white; }

		span {
			position: absolute;
			width: 30%;
			height: 30px;
			display: block;
			top: 50%;
			left: 62%;
			transform: translate(-50%, -50%);
			border: 15px solid transparent;
			border-left: 20px solid $white; }

		&:hover {
			&::before,
			&::after {
				transform: rotate(180deg) translate(-50%, -50%); } } }



	&__text {
		display: block;
		background-color: $white;
		padding: 30px 30px 0;

		h3 {
			margin-bottom: 20px; }

		p {
			margin: 0 0 15px;
			&:last-child {
				margin: 0; } } }

	&__review {
		padding: 15px 30px 30px;
		background-color: $white;

		p {
			color: rgba($black, .5); } }

	&__buttons {
		align-items: center;
		.decor-link {
			margin: 0;
			@include r(767) {
				margin: 0 20px 0 0; } }
		.arrow {
			padding: 0; } }

	&__arrows {
		path,
		rect {
			fill: $violet; } } }



