.advantages {
	position: relative;
	padding: 110px 0;
	@include r(1023) {
		padding: 80px 0; }
	@include r(767) {
		padding: 70px 0; }

	.triangle {
		top: 140px; }

	&__container {
		@include r(1023) {
			flex-direction: column; } }

	&__block {
		max-width: 50%;
		width: 100%;
		@include r(1023) {
			max-width: 100%;
			margin-bottom: 50px;
			&:last-child {
				margin: 0; } }

		.tile {
			&__box {
				@include r(639) {
					max-width: 50%; }
				@include r(479) {
					max-width: 100%; } }

			&__wrapper {
				@include r(639) {
					max-width: 320px;
					width: 100%;
					margin: 0 auto;
					text-align: center; } } } }

	&__text {
		max-width: 540px;
		padding-right: 20px;
		width: 100%;
		font-size: 14px;
		@include bold;
		@include r(639) {
			padding: 0; }

		h2 {
			margin-bottom: 60px; }
		p {
			font-size: 18px;
			line-height: 1.56;
			margin: 0 0 30px;
			&:last-child {
				margin: 0; } } }

	&__box {
		margin-bottom: 60px;
		padding-right: 8%;
		@include r(1023) {
			margin-bottom: 35px;
			padding-right: 20px; }
		@include r(639) {
			padding: 0; }

		&.with-image {
			padding: 0 10px;
			img {
				max-width: 100px;
				height: 100px;
				object-fit: contain;
				margin: 0 auto 30px; }
			.advantages__text {
				text-align: center;
				padding: 0;
				text-transform: uppercase; } } }


	&__number {
		font-size: 72px;
		@include bold;
		color: $violet; } }
