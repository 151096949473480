.news {
	position: relative;
	padding: 100px 0 210px;
	@include r(1023) {
		padding: 60px 0 140px; }

	.triangle {
		top: 0; }

	&__container {
		margin-bottom: 60px; }

	&__grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: -30px;

		@supports (display: grid) {
			margin: 0;
			display: grid;
			grid-template: repeat(2, minmax(340px, 1fr)) / calc(50% - 20px) repeat(2, calc(25% - 20px));
			grid-gap: 30px;
			gap: 30px;
			@include r(1365) {
				grid-template: repeat(2, minmax(270px, 1fr)) / calc(50% - 15px) repeat(2, calc(25% - 15px));
				gap: 20px;
				grid-gap: 20px; }
			@include r(1023) {
				grid-template: repeat(2, 270px) / repeat(2, 1fr); }
			@include r(639) {
				grid-template: 1fr / 1fr; } } }

	&__box {
		padding: 15px;
		min-height: 340px;
		max-width: 33.3333%;
		width: 100%;
		@include r(1023) {
			min-height: 270px;
			max-width: 50%; }
		&:first-child {
			max-width: 66.6667%;
			width: 100%;
			@include r(1023) {
				max-width: 50%; } }

		@supports (display: grid) {
			max-width: none;
			padding: 0;
			min-height: auto;
			@include r(1023) {
				height: 270px; }
			&:first-child {
				max-width: none;
				grid-column: 1/2;
				grid-row: 1/3;
				@include r(1023) {
					grid-column: 1/2;
					grid-row: 1/2; } } } }

	&__wrapper {
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		height: 100%;
		padding: 45px;
		background-color: $violet;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		position: relative;
		z-index: 1;
		transition: background-color .25s;
		@include r(1365) {
			padding: 30px; }

		&:hover {
			background-color: rgba($violet, .8);
			.news__text__img {
				opacity: .8; } } }


	&__text {
		&__img {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			transition: opacity	.25s;
			img {
				object-fit: cover;
				width: 100%;
				height: 100%; } }

		&__date,
		&__descr p,
		&__descr h3 {
			color: $white; }

		&__descr {
			p {
				padding-top: 20px; } } }


	&__more {
		display: inline-block; } }
