.services {
	padding: 220px 0 110px;
	display: block;
	position: relative;
	@include r(1023) {
		padding: 110px 0; }
	@include r(767) {
		padding: 80px 0; }

	&__container {}

	&__slider {
		&:not(.is-flex) {
			@supports(display: grid) {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 30px;
				@include r(1023) {
					grid-template: repeat(2, 1fr) / repeat(2, 1fr); }
				@include r(639) {
					grid-template: 1fr / 1fr; } } }

		&.is-flex {
			display: flex;
			margin: -10px;

			.services__slide {
				width: 100%;
				padding: 10px;
				@include r(1023) {
					max-width: 50%; }

				@supports(display: grid) {
					@include r(1023) {
						max-width: none; } } }

			.services__sub-title {
				margin-bottom: 0; }

			.services__img {
				width: 84px;
				height: 84px;
				margin-bottom: 40px; } } }

	&__slide {
		@include r(1023) {
			max-width: 50%; }

		@supports(display: grid) {
			@include r(1023) {
				max-width: none; } } }

	.decor-link {
		opacity: 0; }

	&__wrapper {
		padding: 50px 30px;
		background-color: $white;
		height: 100%;
		transition: background-color .25s;
		color: $black;
		flex-direction: column;
		align-items: stretch;
		@include r(1023) {
			padding: 40px 25px; }
		@include r(767) {
			padding: 25px;
			width: 100%;
			margin: 0 auto;
			align-items: center; }

		&:hover {
			background-color: $violet;
			color: $white;
			.decor-link {
				opacity: 1; }
			.services__img {
				svg {
					fill: $white; }
				.stroke {
					stroke: $white; } } } }

	&__sub-title h3,
	&__text p,
	&__text,
	&__sub-title {
		color: inherit; }

	&__img {
		max-width: 95px;
		height: 85px;
		margin-bottom: 60px;
		@include r(1023) {
			margin-bottom: 45px; }
		@include r(767) {
			margin-bottom: 30px; }
		svg,
		img {
			width: 100%;
			height: 100%; }

		path,
		g,
		circle {
			gill: $black; } }

	&__sub-title {
		margin-bottom: 30px; }

	&__text {
		margin-bottom: 10px; }

	&__arrows {
		padding-top: 50px;
		justify-content: flex-end;
		&.is-hidden {
			display: none; }
		.arrow {
			path {
				fill: $violet; } } } }
